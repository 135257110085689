import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import * as AddressBookListActions from './address-book.list.actions';

import { AddressBookService } from '../../service/adress-book.service';

@Injectable()
export class AddressBookListEffects {
  private actions$ = inject(Actions);
  private service = inject(AddressBookService);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressBookListActions.initAddressBookList),
      switchMap(() =>
        this.service.loadAddressBookEntryList().pipe(
          map((addressBookList) => AddressBookListActions.loadAddressBookListSuccess({ addressBookList })),
          catchError((error) => of(AddressBookListActions.loadAddressBookListFailure({ error })))
        )
      )
    )
  );
}
