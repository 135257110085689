import { createAction, props } from '@ngrx/store';
import { AddressBookEntry } from '@planone/bau365/util-user-data';
export const initAddressBookList = createAction('[AddressBookList Page] Init');

export const loadAddressBookListSuccess = createAction(
  '[AddressBookList/API] Load AddressBookList Success',
  props<{ addressBookList: AddressBookEntry[] }>()
);

export const loadAddressBookListFailure = createAction(
  '[AddressBookList/API] Load AddressBookList Failure',
  props<{ error: string | null | undefined }>()
);
