import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { CodeType } from '@planone/bau365/data-access-qrcode-scanner';

import {
  AddressBookEntry,
  ApiAddressBookEntry,
  Review,
  transformAddressBookEntry,
} from '@planone/bau365/util-user-data';
import { map, Observable } from 'rxjs';

import { ADDRESS_BOOK_CONFIG } from '../config/address-book.config';

@Injectable()
export class AddressBookService {
  private config = inject(ADDRESS_BOOK_CONFIG);
  private http = inject(HttpClient);

  loadAddressBookEntryList(): Observable<AddressBookEntry[]> {
    return this.http
      .get<{ contacts: ApiAddressBookEntry[] }>(`${this.config.userServiceApiUrl}/address-book`)
      .pipe(map((response) => response.contacts.map(transformAddressBookEntry)));
  }

  loadAddressBookEntry(id: string): Observable<AddressBookEntry> {
    return this.http
      .get<ApiAddressBookEntry>(`${this.config.userServiceApiUrl}/address-book/${id}`)
      .pipe(map(transformAddressBookEntry));
  }

  createAddressBookEntryFromBusinessCard(id: string, code_type: CodeType): Observable<string> {
    return this.http.post<string>(`${this.config.userServiceApiUrl}/address-book`, {
      scanned_value: id,
      type: code_type,
    });
  }

  submitReview(addressBookEntryReview: Review, id: string | undefined): Observable<AddressBookEntry> {
    return this.http
      .put<ApiAddressBookEntry>(`${this.config.userServiceApiUrl}/address-book/${id}/reviews`, {
        comment: addressBookEntryReview.comment,
        rating: addressBookEntryReview.rating,
      })
      .pipe(map(transformAddressBookEntry));
  }
}
