import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as AddressBookListActions from './address-book.list.actions';
import * as AddressBookListSelectors from './address-book.list.selectors';

@Injectable()
export class AddressBookListFacade {
  private readonly store = inject(Store);

  loaded$ = this.store.pipe(select(AddressBookListSelectors.selectAddressBookListLoaded));
  addressBookList$ = this.store.pipe(select(AddressBookListSelectors.selectAllAddressBookList));
  error$ = this.store.pipe(select(AddressBookListSelectors.selectAddressBookListError));

  init() {
    this.store.dispatch(AddressBookListActions.initAddressBookList());
  }
}
