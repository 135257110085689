import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';

import * as AddressBookEntryActions from './address-book.entry.actions';

import { ratingFormSubmittedSuccess } from './address-book.entry.actions';
import * as AddressBookEntrySelectors from './address-book.entry.selectors';

import { AddressBookService } from '../../service/adress-book.service';

@Injectable()
export class AddressBookEntryEffects {
  private actions$ = inject(Actions);
  private service = inject(AddressBookService);
  private store = inject(Store);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressBookEntryActions.initAddressBookEntry),
      switchMap(({ id }) =>
        this.service.loadAddressBookEntry(id).pipe(
          map((addressBookEntry) => AddressBookEntryActions.loadAddressBookEntrySuccess({ addressBookEntry })),
          catchError((error) => of(AddressBookEntryActions.loadAddressBookEntryFailure({ error })))
        )
      )
    )
  );

  reviewSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressBookEntryActions.ratingFormSubmitted),
      withLatestFrom(this.store.pipe(select(AddressBookEntrySelectors.selectAddressBookEntry))),
      switchMap(([action, entry]) =>
        this.service.submitReview(action, entry?.id).pipe(
          map((addressBookEntry) => ratingFormSubmittedSuccess({ addressBookEntry })),
          catchError((error) => of(AddressBookEntryActions.ratingFormSubmittedFailure({ error })))
        )
      )
    )
  );
}
