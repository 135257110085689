import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ADDRESS_BOOK_LIST_FEATURE_KEY,
  addressBookListAdapter,
  AddressBookListState,
} from './address-book.list.reducer';

export const selectAddressBookListState = createFeatureSelector<AddressBookListState>(ADDRESS_BOOK_LIST_FEATURE_KEY);

const { selectAll, selectEntities } = addressBookListAdapter.getSelectors();

export const selectAddressBookListLoaded = createSelector(
  selectAddressBookListState,
  (state: AddressBookListState) => state.loaded
);

export const selectAddressBookListError = createSelector(
  selectAddressBookListState,
  (state: AddressBookListState) => state.error
);

export const selectAllAddressBookList = createSelector(selectAddressBookListState, (state: AddressBookListState) =>
  selectAll(state)
);

export const selectAddressBookListEntities = createSelector(selectAddressBookListState, (state: AddressBookListState) =>
  selectEntities(state)
);
