import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessTogglesFacade } from '@planone/shared/data-access-feature-flags';
import { map } from 'rxjs';

export const featureFlag = () => {
  const router = inject(Router);
  return inject(DataAccessTogglesFacade)
    .flag('show-address-book')
    .pipe(map((showTickets) => (showTickets ? true : router.parseUrl('/'))));
};
