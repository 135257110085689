import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ADDRESS_BOOK_ENTRY_FEATURE_KEY, AddressBookEntryState } from './address-book.entry.reducer';

export const selectAddressBookEntryState = createFeatureSelector<AddressBookEntryState>(ADDRESS_BOOK_ENTRY_FEATURE_KEY);

export const selectAddressBookEntryLoaded = createSelector(
  selectAddressBookEntryState,
  (state: AddressBookEntryState) => state.addressBookEntryLoaded
);

export const selectAddressBookEntryError = createSelector(
  selectAddressBookEntryState,
  (state: AddressBookEntryState) => state.error
);

export const selectAddressBookEntry = createSelector(
  selectAddressBookEntryState,
  (state: AddressBookEntryState) => state.addressBookEntry
);
