import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { DataAccessTogglesFacade } from '@planone/shared/data-access-feature-flags';
import { Observable, of, switchMap } from 'rxjs';

import { AddressBookService } from '../service/adress-book.service';

export const createAddressBookEntry: ResolveFn<string | undefined> = (
  route: ActivatedRouteSnapshot
): Observable<string | undefined> => {
  const addressBookService = inject(AddressBookService);
  return inject(DataAccessTogglesFacade)
    .flag('show-address-book')
    .pipe(
      switchMap((showAddressBook) =>
        showAddressBook
          ? addressBookService.createAddressBookEntryFromBusinessCard(
              route.queryParams['id'],
              route.queryParams['codeType']
            )
          : of(undefined)
      )
    );
};
