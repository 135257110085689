import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AddressBookService } from './service/adress-book.service';
import { AddressBookEntryEffects } from './state/entry/address-book.entry.effects';
import { AddressBookEntryFacade } from './state/entry/address-book.entry.facade';
import * as fromAddressBookEntry from './state/entry/address-book.entry.reducer';
import { AddressBookListEffects } from './state/list/address-book.list.effects';
import { AddressBookListFacade } from './state/list/address-book.list.facade';
import * as fromAddressBookList from './state/list/address-book.list.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromAddressBookList.ADDRESS_BOOK_LIST_FEATURE_KEY,
      fromAddressBookList.addressBookListReducer
    ),
    EffectsModule.forFeature([AddressBookListEffects]),
    StoreModule.forFeature(
      fromAddressBookEntry.ADDRESS_BOOK_ENTRY_FEATURE_KEY,
      fromAddressBookEntry.addressBookEntryReducer
    ),
    EffectsModule.forFeature([AddressBookEntryEffects]),
  ],
  providers: [AddressBookService, AddressBookListFacade, AddressBookEntryFacade],
})
export class Bau365DataAccessAddressBookModule {}
