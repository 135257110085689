import { Action, createReducer, on } from '@ngrx/store';
import { AddressBookEntry } from '@planone/bau365/util-user-data';

import * as AddressBookEntryActions from './address-book.entry.actions';

export const ADDRESS_BOOK_ENTRY_FEATURE_KEY = 'addressBookEntry';

export interface AddressBookEntryState {
  addressBookEntry: AddressBookEntry | undefined;
  addressBookEntryLoaded: boolean;
  error?: string | null;
}

export interface AddressBookEntryPartialState {
  readonly [ADDRESS_BOOK_ENTRY_FEATURE_KEY]: AddressBookEntryState;
}

export const initialAddressBookEntryState: AddressBookEntryState = {
  addressBookEntry: undefined,
  addressBookEntryLoaded: false,
};

const reducer = createReducer(
  initialAddressBookEntryState,
  on(AddressBookEntryActions.initAddressBookEntry, (state) => ({
    ...state,
    addressBookEntryLoaded: false,
    error: null,
  })),
  on(
    AddressBookEntryActions.loadAddressBookEntrySuccess,
    AddressBookEntryActions.ratingFormSubmittedSuccess,
    (state, { addressBookEntry }) => ({
      ...state,
      addressBookEntry,
      loaded: true,
    })
  ),
  on(AddressBookEntryActions.loadAddressBookEntryFailure, (state, { error }) => ({ ...state, error }))
);

export function addressBookEntryReducer(state: AddressBookEntryState | undefined, action: Action) {
  return reducer(state, action);
}
