import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Review } from '@planone/bau365/util-user-data';

import * as AddressBookEntryActions from './address-book.entry.actions';
import * as AddressBookEntrySelectors from './address-book.entry.selectors';

@Injectable()
export class AddressBookEntryFacade {
  private readonly store = inject(Store);

  loaded$ = this.store.pipe(select(AddressBookEntrySelectors.selectAddressBookEntryLoaded));
  addressBookEntry$ = this.store.pipe(select(AddressBookEntrySelectors.selectAddressBookEntry));
  error$ = this.store.pipe(select(AddressBookEntrySelectors.selectAddressBookEntryError));

  init(id: string) {
    this.store.dispatch(AddressBookEntryActions.initAddressBookEntry({ id }));
  }

  ratingFormSubmitted(reviewData: Review) {
    this.store.dispatch(AddressBookEntryActions.ratingFormSubmitted(reviewData));
  }
}
