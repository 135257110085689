import { createAction, props } from '@ngrx/store';
import { AddressBookEntry } from '@planone/bau365/util-user-data';
export const initAddressBookEntry = createAction('[AddressBookEntry Page] Init', props<{ id: string }>());

export const loadAddressBookEntrySuccess = createAction(
  '[AddressBookEntry/API] Load AddressBookEntry Success',
  props<{ addressBookEntry: AddressBookEntry }>()
);

export const loadAddressBookEntryFailure = createAction(
  '[AddressBookEntry/API] Load AddressBookEntry Failure',
  props<{ error: string | null | undefined }>()
);

export const ratingFormSubmitted = createAction(
  '[AddressBookEntry] Rating form submitted',
  props<{ comment: string; rating: number }>()
);

export const ratingFormSubmittedSuccess = createAction(
  '[AddressBookEntry/API] Submit rating form success',
  props<{ addressBookEntry: AddressBookEntry }>()
);

export const ratingFormSubmittedFailure = createAction(
  '[AddressBookEntry/API] Submit rating form failed',
  props<{ error: string | null | undefined }>()
);
