import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AddressBookEntry } from '@planone/bau365/util-user-data';

import * as AddressBookListActions from './address-book.list.actions';

export const ADDRESS_BOOK_LIST_FEATURE_KEY = 'addressBookList';

export interface AddressBookListState extends EntityState<AddressBookEntry> {
  loaded: boolean;
  error?: string | null;
}

export interface AddressBookListPartialState {
  readonly [ADDRESS_BOOK_LIST_FEATURE_KEY]: AddressBookListState;
}

export const addressBookListAdapter: EntityAdapter<AddressBookEntry> = createEntityAdapter<AddressBookEntry>();

export const initialAddressBookListState: AddressBookListState = addressBookListAdapter.getInitialState({
  loaded: false,
});

const reducer = createReducer(
  initialAddressBookListState,
  on(AddressBookListActions.initAddressBookList, (state) => ({ ...state, loaded: false, error: null })),
  on(AddressBookListActions.loadAddressBookListSuccess, (state, { addressBookList }) =>
    addressBookListAdapter.setAll(addressBookList, { ...state, loaded: true })
  ),
  on(AddressBookListActions.loadAddressBookListFailure, (state, { error }) => ({ ...state, error }))
);

export function addressBookListReducer(state: AddressBookListState | undefined, action: Action) {
  return reducer(state, action);
}
